
import { useRouter } from "vue-router"
import TertiaryButton from "./buttons/TertiaryButton.vue"

export default {
  name: "Forbidden",
  components: { TertiaryButton },
  setup() {
    const router = useRouter()

    function goBack() {
      router.back()
    }

    return {
      goBack,
    }
  },
}
